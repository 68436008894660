import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RecipeConstants } from '@gfs/constants';
import {
  Cart,
  CustomerPK,
  ItemReference,
  Recipe, RecipeIngredient,
  RecipePrice,
  RecipePriceRequest
} from '@gfs/shared-models';
import { mapOrdinal as mapOrdinalImport, sanitizeIds as sanitizeIdsImport } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap, toArray } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';
import { MessageService } from '../../lib/services/message.service';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  private baseUrl: string;
  recipeSearchTerm$ = new BehaviorSubject<string>('');

  matchipMapping: { [recipeType: string]: any } = {
    batch: { icon: 'batch-recipe', i18n: 'CATEGORY.RECIPE_CARD.BATCH_RECIPE' },
    menuitem: { icon: 'menu-item', i18n: 'CATEGORY.RECIPE_CARD.MENU_ITEM' },
  };

  // Constructor does not capture this dependency. Assigning to local function facilitates unit testing
  sanitizeIds = sanitizeIdsImport;
  mapOrdinal = mapOrdinalImport;

  sanitizeRecipeIngredientIds = (recipe: Recipe): Recipe => ({
    ...recipe,
    ingredients: this.sanitizeIds(recipe.ingredients, [RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER])
  });

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getRecipes(customerPK: CustomerPK): Observable<Recipe[]> {
    return this.http
      .get<Recipe[]>(`${this.baseUrl}/recipes`, { params: { ...customerPK } })
      .pipe(
        concatMap(recipes => from(recipes)),
        map(x => ({
          ...x,
          subType: x.details.menu ? 'menuItem' : 'batch',
          itemType: 'RECIPE'
        }) as Recipe),
        toArray(),
        catchError((error) => this.logError<Recipe[]>(error, 'getRecipes', []))
      );
  }

  searchRecipes(
    customerPK: CustomerPK,
    searchText: string
  ): Observable<Recipe[]> {
    const params = { ...customerPK, name: searchText };
    return this.http
      .get<Recipe[]>(`${this.baseUrl}/recipes/search`, { params })
      .pipe(
        catchError(error => this.logError<Recipe[]>(error, 'searchRecipes', []))
      );
  }


  createRecipe(newRecipe: Recipe, isDuplicate: boolean): Observable<Recipe> {
    const recipePayload = this.sanitizeRecipeIngredientIds(newRecipe);
    return this.http.post<Recipe>(`${this.baseUrl}/recipes`, recipePayload).pipe(
      tap((recipe: Recipe) => this.notifyUser(
        isDuplicate
          ? this.translate.instant(
            'MESSAGES.DUPLICATE_RECIPE_CREATION_MESSAGE',
            { value: recipe.name }
          )
          : this.translate.instant('MESSAGES.RECIPE_CREATION_MESSAGE', {
            value: recipe.name,
          })
      )),
      catchError((error) => this.logError<Recipe>(error, 'createRecipe', newRecipe))
    );
  }

  patchRecipe(recipe: Recipe): Observable<Recipe> {
    const recipePayload = this.sanitizeRecipeIngredientIds(recipe);
    return this.http
      .patch<Recipe>(`${this.baseUrl}/recipes/${recipePayload.id}`, recipePayload)
      .pipe(
        tap((updated) =>
          this.notifyUser(
            this.translate.instant('MESSAGES.RECIPE_UPDATED_MESSAGE', {
              value: updated.name,
            })
          )
        ),
        catchError((error) => this.logError<Recipe>(error, 'patchRecipe', recipe))
      );
  }

  deactivateRecipe(recipe: Recipe): Observable<any> {
    const recipeId = recipe.id;

    return this.http.delete<void>(`${this.baseUrl}/recipes/${recipeId}`).pipe(
      tap((_) =>
        this.notifyUser(
          this.translate.instant('MESSAGES.RECIPE_DELETION_MESSAGE', {
            value: recipe.name,
          })
        )
      ),
      catchError((error) => this.logError<void>(error, 'createCategory', null))
    );
  }

  getIngredientsByRecipeId(payload: {
    recipeId: string;
  }): Observable<RecipeIngredient[]> {
    return this.http
      .get<RecipeIngredient[]>(`${this.baseUrl}/recipes/${payload.recipeId}/ingredients`)
      .pipe(
        map(ingredients => this.mapOrdinal(ingredients)),
        catchError((error) => this.logError(error, 'createIngredientForRecipe', [])))
      ;
  }

  getAllSavedUnsavedIngredients(payload: { 
    customerPk : CustomerPK;
    ItemReference?:ItemReference[];
    recipeId: string;
  }): Observable<RecipeIngredient[]> {
    return this.http
      .post<RecipeIngredient[]>(`${this.baseUrl}/recipes/${payload.recipeId}/getAllSavedUnsavedIngredients`, 
      {
       unsavedIngredients : payload.ItemReference as ItemReference[],
       customerPK : {
        salesOrg : payload.customerPk.salesOrg,
        channel : payload.customerPk.channel,
        division : payload.customerPk.division,
        customerId: payload.customerPk.customerId,
        entityType: payload.customerPk.entityType
      }

      })
      .pipe(
        map(ingredients => this.mapOrdinal(ingredients)),
        catchError((error) => this.logError(error, 'createIngredientForRecipe', [])))
      ;
  }

  createIngredientsForRecipe(payload: {
    recipeId: string;
    ingredients: RecipeIngredient[];
  }): Observable<RecipeIngredient[]> {
    return this.http
      .post<RecipeIngredient[]>(`${this.baseUrl}/recipes/${payload.recipeId}/ingredients`, payload.ingredients)
      .pipe(
        map(ingredients => this.mapOrdinal(ingredients)),
        catchError((error) => this.logError(error, 'patchIngredients', payload.ingredients))
      );
  }
  addIngredientToCart(payload: {
    itemId: string;
    quantity:string;
    unitType:string;
    customerPK:CustomerPK;
    includePrice:boolean
  }): Observable<Cart> {
    return this.http
      .post<Cart>(
        `${this.baseUrl}/addToCarts`,
      {
          itemId : payload.itemId,
          quantity: payload.quantity,
          unitType : payload.unitType,
          includePrice: payload.includePrice,
          customerPK: payload.customerPK
      }
      ).pipe(
        catchError((error) => this.logError(error, ' Please try again.', null))
      )
  }

  refreshRecipePriceById(recipeId: string): Observable<RecipePrice> {
    return this.http
      .post<RecipePrice>(`${this.baseUrl}/recipes/${recipeId}/refresh-price`, {})
      .pipe(
        catchError((error) => this.logError(error, 'refreshRecipePriceById', null))
      );
  }

  priceCalculator(request: RecipePriceRequest): Observable<RecipePrice> {
    return this.http.post<RecipePrice>(`${this.baseUrl}/price-calculator`, request)
      .pipe(
        map(result => {
          return ({
            ...result,
            margin: calculateRecipeMargin(result)
          } as RecipePrice);
        }),
        catchError((error) =>
          this.logError<RecipePrice>(error, 'priceCalculator', {
            recipeId: '',
            customerPK: request.customerPK,
            foodCostPercent: 0,
            menuPrice: 0,
            totalCost: 0,
            margin: 0,
            ingredientPrices: [],
            lastUpdated: '',
          } as RecipePrice)),
      );
  }

  logError<T>(error: any, operation: string, result?: T): Observable<T> {
    console.log(error);

    const localizedErrorMessage = this.translate.instant(
      'MESSAGES.SNACKBAR_ERROR_MESSAGE'
    );

    this.notifyUser(`${localizedErrorMessage} ${operation}`);
    return of(result);
  }

  private notifyUser(message: string): void {
    this.messageService.queue(message);
  }

  setRecipeNameFilter(answer: string): void {
    this.recipeSearchTerm$.next(answer);
  }

  resolveRecipeChip(recipe: Recipe) {
    const isBatch = recipe.details.batch !== null;
    const resolvedType = isBatch ? 'batch' : 'menuitem';
    return this.matchipMapping[resolvedType];
  }
}

function calculateRecipeMargin(result: RecipePrice) {
  return +((+result.totalCost - +result.menuPrice) * -1).toFixed(2);
}
