import { Injectable } from '@angular/core';
import { RecipeConstants } from '@gfs/constants';
import {
    CustomerPK,
    CustomItemData,
    ItemReference,
    ItemUnits,
    MeasurementUnit,
    MeasurementUnitGroup,
    PortioningUnit,
    RecipeIngredient,
    ResolvedItem,
    ResolvedMeasurementUnitGroup,
    ResolvedUnitOfMeasure,
    UnitOfMeasureConfiguration
} from '@gfs/shared-models';
import {
    CustomItemDataService,
    CustomItemService,
    GeneralItemService,
    ProductService,
    RecipeService
} from '@gfs/shared-services';
import { arrayToEntityDictionary } from '@gfs/shared-services/core/entityUtil';
import { asLocalizedValue, caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { firstValueFrom, hasElements, isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { MeasurementUnitService } from '@gfs/shared-services/services/measurement-unit.service';
import {  EMPTY, forkJoin, from, merge, Observable, of } from 'rxjs';
import {
    catchError, concatMap, distinct,
    filter, first, map, mergeAll,
    mergeMap, share, toArray, withLatestFrom ,combineLatest, switchMap, combineLatestAll
} from 'rxjs/operators';
import { ItemUnitConfiguration } from './unit-source-config';
import { UnitsHttpService } from '../unit/unit-http-service.service';


export type UnitConfigurationResolver = (x: ResolvedItem, availableUnits: MeasurementUnit[]) => Observable<boolean>;
@Injectable({
    providedIn: 'root',
})
export class ItemDataBLL {
    constructor(
        private recipeService: RecipeService,
        private productService: ProductService,
        private generalItemService: GeneralItemService,
        private customItemService: CustomItemService,
        private customItemDataService: CustomItemDataService,
        private measurementUnitService: MeasurementUnitService,
        private unitConfig: ItemUnitConfiguration,
        private unitService: UnitsHttpService
    ) { }

    prepareItemRefs$(
        itemRefs$: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ItemReference[]> {
        return forkJoin([
            this.resolveLinkedItemReferences$(itemRefs$, customerPK$),
            this.getDirectItemReferences$(itemRefs$)
        ]).pipe(
            mergeAll(),
            mergeAll(),
            toArray(),
            share()
        );
    }

    loadCoreItemData$(
        itemReferences$: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return merge(
            this.loadUserDefinedItemType$(itemReferences$, customerPK$),
            this.loadGFSItemType$(itemReferences$, customerPK$),
            this.loadRecipeItemType$(itemReferences$, customerPK$)
        ).pipe(
            mergeAll(),
            toArray(),
            share(),

        );
    }

    loadCustomItemData$(
        items$: Observable<ResolvedItem[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return items$.pipe(
            concatMap(resolvedItems => from(resolvedItems)),
            withLatestFrom(customerPK$),
            mergeMap(([resolvedItem, customerPK]) => {
                return forkJoin([
                    of(resolvedItem),
                    this.customItemDataService
                        .getCustomItemData(customerPK, resolvedItem.itemReference.key, resolvedItem.itemReference.type)
                        .pipe(
                            concatMap(x => from(x ?? [])),
                            first(x => !!x, {})
                        ),
                ]);
            }
            ),
            map(([resolvedItem, customItemData]) => ({ ...resolvedItem, customItemData })),
            toArray<ResolvedItem>(),
        ).pipe(
            first(),
            share(),
        );
    }

    loadUnitOfMeasure$(
        enrichedItemsCustomItemData$: Observable<ResolvedItem[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return enrichedItemsCustomItemData$
            .pipe(
                concatMap(items => from(items)),
                concatMap((item) => {
                    const measurementUnits$ = this.getAppMeasurementUnits$(customerPK$);
                    return this.loadItemUnits(item, measurementUnits$);
                }),
                toArray(),
            ).pipe(
                first(),
                share()
            );
    }

    private getAppMeasurementUnits$(
        customerPK$: Observable<CustomerPK>
    ) {
        return customerPK$.pipe(
            concatMap(customerPK =>
                this.measurementUnitService.getMeasurementUnits(customerPK)
            )
        );
    }

    private resolveLinkedItemReferences$(
        allItemRefs$: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ItemReference[]> {
        return allItemRefs$.pipe(
            concatMap(itemRefs => from(itemRefs)),
            filter(x => caseInsensitiveEquals(x.type, 'GENERAL')),
            toArray(),
            hasElements(),
            concatMap(itemRefs => forkJoin([
                of(itemRefs),
                from(itemRefs)
                    .pipe(
                        map(ref => ref.key),
                        distinct(),
                        toArray()
                    )
            ])),
            withLatestFrom(customerPK$),
            concatMap(([[itemRefs, keys], customerPK]) => forkJoin([
                of(itemRefs),
                this.generalItemService.getGeneralItems(keys, true, customerPK)
                    .pipe(catchError(() => EMPTY)),
            ])),
        ).pipe(
            concatMap(([itemRefs, generalItems]) => from(itemRefs)
                .pipe(
                    withLatestFrom(of(arrayToEntityDictionary(generalItems, e => e.id))),
                    map(([itemRef, generalItemDictionary]) => ({
                        generalItem: generalItemDictionary[itemRef.key],
                        itemRef,
                    }))
                )),
            concatMap((worker) => forkJoin([
                of(worker.itemRef),
                from(worker.generalItem.productList)
                    .pipe(
                        filter(x => x.primaryProduct),
                        first()
                    )
            ])),
            map(([itemRef, primaryProduct]) => {
                const r = ({
                    key: primaryProduct.id,
                    type: primaryProduct.type,
                } as ItemReference);

                itemRef.child = r;
                r.parent = itemRef;
                return r;
            }),
            toArray(),
            share()
        );
    }

    private getDirectItemReferences$(
        itemRefs$: Observable<ItemReference[]>
    ): Observable<ItemReference[]> {
        return itemRefs$.pipe(
            concatMap(allItems => from(allItems)),
            filter(x => !caseInsensitiveEquals(x.type, 'GENERAL')),
            toArray(),
            share(),
        );
    }

    private loadGFSItemType$(
        referenceSource: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return referenceSource.pipe(
            concatMap(groups => groups),
            filter(x => caseInsensitiveEquals(x.type, 'GFS')),
            toArray(),
            concatMap(itemRefs => forkJoin([
                of(itemRefs),
                from(itemRefs).pipe(map(p => p.key), distinct(), toArray())
            ]))
        ).pipe(
            withLatestFrom(customerPK$),
            concatMap(([[itemRefs, itemIds], customerPK]) => forkJoin([
                of(itemRefs),
                this.productService.getProductsInfo(itemIds, customerPK)
                    .pipe(catchError(() => EMPTY)),
            ])),
        ).pipe(
            concatMap(([itemRefs, results]) => from(itemRefs).pipe(
                withLatestFrom(of(arrayToEntityDictionary(results, e => e.id))),
                map(([itemRef, resultDictionary]) => ({ itemRef, gfsItem: resultDictionary[itemRef.key] })),
                toArray()
            )),
            concatMap(products => from(products)),
            map(({ itemRef, gfsItem }) => ({
                gfsItem,
                itemReference: itemRef
            } as ResolvedItem)),
            toArray(),
            first(),
            share()
        );
    }

    private loadRecipeItemType$(
        referenceSource: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return referenceSource.pipe(
            concatMap(allItems => from(allItems)),
            filter(item => caseInsensitiveEquals(item.type, 'RECIPE')),
            distinct(),
            toArray(),
            hasElements(),
            concatMap(g => forkJoin([
                of(g),
                from(g).pipe(map(e => e.key), distinct(), toArray())
            ])),
        ).pipe(
            withLatestFrom(customerPK$),
            concatMap(([[itemRefs, itemIds], customerPK]) =>
                forkJoin([
                    of(itemRefs),
                    this.recipeService.getRecipes(customerPK)
                        .pipe(
                            concatMap((recipes) => from(recipes)),
                            filter((y) => itemIds.includes(y.id)),
                            concatMap((r) => {
                                // @note infer and set the correct recipe item type during data load
                                r.itemType = r.subType === 'batch' ? 'BATCHRECIPE' : 'MENUITEMRECIPE';
                                return forkJoin([
                                    of(r),
                                    this.recipeService.getIngredientsByRecipeId({ recipeId: r.id })
                                        .pipe(
                                            first(n => {
                                                return !!n;
                                            }, [] as RecipeIngredient[]),
                                        )
                                ]).pipe(
                                    map(([parentRecipe, ingredients]) => ({ ...parentRecipe, ingredients })),
                                );
                            }),
                            toArray()
                        )
                ])
            ),
            concatMap(([itemRefs, recipeResults]) => from(itemRefs).pipe(
                withLatestFrom(of(arrayToEntityDictionary(recipeResults, e => e.id))),
                map(([itemRef, resultDictionary]) => {
                    return ({
                        itemRef,
                        recipeItem: resultDictionary[itemRef.key] ?? { id: null }
                    });
                })
            )),
            map(({ recipeItem, itemRef }) => {
                return ({
                    recipeItem,
                    itemReference: itemRef
                } as ResolvedItem);
            }),
            toArray(),
            first(),
            share(),
        );
    }

    private loadUserDefinedItemType$(
        referenceSource: Observable<ItemReference[]>,
        customerPK$: Observable<CustomerPK>
    ): Observable<ResolvedItem[]> {
        return referenceSource
            .pipe(
                concatMap(allItems => allItems),
                filter(item => caseInsensitiveEquals(item.type, 'CUSTOM')),
                toArray(),
                concatMap(h => forkJoin([
                    of(h),
                    from(h).pipe(map(g => g.key), distinct(), toArray())
                ]))
            )
            .pipe(
                withLatestFrom(customerPK$),
                concatMap(([[refs, keys], customerPK]) =>
                    forkJoin([
                        of(refs),
                        this.customItemService.getCustomItems(keys, true, customerPK)
                    ])
                ),
                concatMap(([refs, datas]) => {
                    const datasDictionary$ = of(arrayToEntityDictionary(datas, e => e.id));
                    return from(refs).pipe(
                        withLatestFrom(datasDictionary$),
                        map(([itemRef, resultDictionary]) => {
                            return {
                                itemRef,
                                customItem: resultDictionary[itemRef.key]
                            };
                        }),
                        toArray()
                    );
                }),
                concatMap((customItems) => from(customItems)),
                map(({ customItem, itemRef }) =>
                ({
                    customItem,
                    itemReference: itemRef
                } as ResolvedItem)),
                toArray(),
                first(),
                share(),
            );
    }

    private loadItemUnits(
        sourceItem: ResolvedItem,
        measurementUnits$: Observable<MeasurementUnit[]>
    ): Observable<ResolvedItem> {
        return forkJoin([
            of(sourceItem),
            this.resolveItemUnitsOfMeasure$(sourceItem, measurementUnits$)
        ]).pipe(
            map(([item, units]) => ({ ...item, units } as ResolvedItem)),
        );
    }

    private resolveItemUnitsOfMeasure$(
        sourceItem: ResolvedItem,
        measurementUnits$: Observable<MeasurementUnit[]>
    ): Observable<ItemUnits> {
        return of(sourceItem)
            .pipe(
                concatMap(item => forkJoin([of(item), firstValueFrom(measurementUnits$)])),
                concatMap(([item, measurementUnits]) => {
                    return forkJoin([
                        of(item),
                        merge(
                            // @note unit type: standard units
                            this.getSortedStandardUnitsForItem$(measurementUnits, item),
                            // @note unit type: GFS Units from the backend
                            this.resolveGFSUnits$(measurementUnits),
                            // @note user defined units
                            this.resolveUserDefinedUnitsFromItem$(item)
                        ).pipe(mergeAll(), toArray())
                    ]);
                }),
                concatMap(([item, groups]) => {
                    return forkJoin([
                        of(groups),
                        firstValueFrom(measurementUnits$)
                            .pipe(
                                concatMap(units => this.resolveUnitConfigurationAvailability$(units, item)),
                            )
                    ]);
                }),
                map(([
                    group,
                    {
                        hasVolumeConfig: hasVolumeUnitConfiguration,
                        hasWeightConfig: hasWeightUnitConfiguration
                    }
                ]) => ({
                    hasVolumeUnitConfiguration,
                    hasWeightUnitConfiguration,
                    units: group
                } as ItemUnits)),
            );
    }

    private resolveUnitConfigurationAvailability$(
        unitSource: MeasurementUnit[],
        item: ResolvedItem
    ): Observable<UnitOfMeasureConfiguration> {
        return forkJoin([
            this.hasMeasurementUnitConfiguration$(
                unitSource,
                item,
                'WEIGHT'
            ).pipe(catchError(() => of(false))),
            this.hasMeasurementUnitConfiguration$(
                unitSource,
                item,
                'VOLUME'
            ).pipe(catchError(() => of(false)))
        ]).pipe(
            map(([hasWeightConfig, hasVolumeConfig]) => ({ hasWeightConfig, hasVolumeConfig } as UnitOfMeasureConfiguration)),
        );
    }

    private resolveGFSUnits$(
        measurementUnits: MeasurementUnit[]
    ) {
        return merge(
            this.sortedRecipeUnits$(of(measurementUnits)),
            this.sortedWeightUnits$(of(measurementUnits)),
            this.sortedVolumeUnits$(of(measurementUnits)),
            this.batchRecipeYieldUnits$(of(measurementUnits))
        ).pipe(
            concatMap(({ group, units }) => forkJoin([
                of(group.toUpperCase()),
                of(units),
                of(units).pipe(
                    concatMap(unitsCollection => from(unitsCollection)),
                    map(unit => this.mapToResolvedUnitOfMeasure(unit)),
                    toArray()
                )
            ])
            ),
            map(([group, unitSource, units]) => ({
                group,
                unitSource,
                units
            } as ResolvedMeasurementUnitGroup)),
            toArray(),
        );
    }

    private resolveUserDefinedUnitsFromItem$(
        item: ResolvedItem
    ): Observable<ResolvedMeasurementUnitGroup[]> {
        return of(item)
            .pipe(
                map(x => x.customItemData),
                isTruthy(),
                concatMap((customItemData) => {
                    return forkJoin([
                        // @note all custom unit sources will resolve in this forkjoin
                        this.resolveRecipeUnits$(customItemData)
                    ]).pipe(
                        catchError(() => of([] as ResolvedUnitOfMeasure[])),
                        mergeAll(),
                        map(units => {
                            return ({
                                group: 'CUSTOM',
                                units,
                            } as ResolvedMeasurementUnitGroup);
                        }),
                    );
                }
                ),
                toArray(),
                catchError(() => of([] as ResolvedMeasurementUnitGroup[])),
            );
    }

    private resolveRecipeUnits$(customItemData: CustomItemData) {
        return of(customItemData).pipe(
            map(data => data.recipeUnits ?? ([] as PortioningUnit[])),
            catchError(() => of([] as PortioningUnit[]))
        )
            .pipe(
                concatMap(portioningUnits => from(portioningUnits)),
                // @note unit type: recipe portion
                map(unit => unit.custom),
                isTruthy(),
                map(customPortioningUnit => ({
                    key: customPortioningUnit.id,
                    localized: asLocalizedValue(customPortioningUnit.name),
                    localizedSymbol: []
                } as ResolvedUnitOfMeasure)),
                toArray(),
                catchError(() => of([] as ResolvedUnitOfMeasure[]))
            );
    }

    private getSortedStandardUnitsForItem$(
        measurementUnits: MeasurementUnit[],
        item: ResolvedItem
    ): Observable<ResolvedMeasurementUnitGroup[]> {
        return merge(
            this.sortedStandardUnits$(of(measurementUnits))
                .pipe(
                    concatMap(units => from(units)),
                    map(unit => this.mapToResolvedUnitOfMeasure(unit)),
                    toArray(),
                    catchError(() => of([] as ResolvedUnitOfMeasure[])),
                ),
            of(item)
                .pipe(
                    filter(resolvedItem => caseInsensitiveEquals(resolvedItem.itemReference.type, 'GFS')),
                    map(resolvedItem => resolvedItem.gfsItem.portionUom),
                    filter(uom => uom.length > 0),
                    map(portionUom => {
                        return ({
                            key: `${portionUom}`,
                            localized: asLocalizedValue(portionUom),
                            localizedSymbol: []
                        } as ResolvedUnitOfMeasure);
                    }),
                    toArray(),
                    catchError(() => of([] as ResolvedUnitOfMeasure[])),
                ),
        ).pipe(
            mergeAll(), // @note merge stream of arrays into stream of elements
            toArray(),  // @note merge stream of elements into array of elements
            map(unitGroup => ({
                group: RecipeConstants.MEASUREMENT_TYPE.COUNT.toUpperCase(),
                units: unitGroup
            } as ResolvedMeasurementUnitGroup)),
            toArray(),
        );
    }

    private mapToResolvedUnitOfMeasure(unit: MeasurementUnit): ResolvedUnitOfMeasure {
        return ({
            key: unit.id,
            localized: unit.name,
            localizedSymbol: unit.symbol
        } as ResolvedUnitOfMeasure);
    }

    private sortedVolumeUnits$(
        source: Observable<MeasurementUnit[]>
    ): Observable<MeasurementUnitGroup> {
        return source.pipe(
            concatMap(units => from(units)),
            filter(
                (unit) =>
                    unit.measurementType === RecipeConstants.MEASUREMENT_TYPE.VOLUME &&
                    unit.measurementSystem !== RecipeConstants.IMPERIAL_MEASUREMENT_SYSTEM
            ),
            toArray(),
            map((units) =>
                [...units].sort((a: MeasurementUnit, b: MeasurementUnit): number => {
                    if (a.measurementSystem !== b.measurementSystem) {
                        if (a.measurementSystem === RecipeConstants.METRIC_MEASUREMENT_SYSTEM) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    return a.size - b.size;
                })
            ),
            map(units => ({
                group: RecipeConstants.MEASUREMENT_TYPE.VOLUME,
                units
            } as MeasurementUnitGroup))
        );
    }

    // this produces a subset of measurement units and is aggregated into a group elsewhere
    private sortedStandardUnits$(
        source: Observable<MeasurementUnit[]>
    ): Observable<MeasurementUnit[]> {
        return source.pipe(
            concatMap(units => from(units)),
            filter((unit) => unit.measurementType === RecipeConstants.MEASUREMENT_TYPE.COUNT),
            toArray(),
        );
    }

    private sortedWeightUnits$(
        source: Observable<MeasurementUnit[]>
    ): Observable<MeasurementUnitGroup> {
        return source.pipe(
            concatMap(units => from(units)),
            filter(
                (unit) =>
                    unit.measurementType === RecipeConstants.MEASUREMENT_TYPE.WEIGHT
                    &&
                    unit.measurementSystem !== RecipeConstants.IMPERIAL_MEASUREMENT_SYSTEM
            ),
            toArray(),
            map(units => [...units].sort((a: MeasurementUnit, b: MeasurementUnit): number => {
                if (a.measurementSystem !== b.measurementSystem) {
                    return a.measurementSystem === RecipeConstants.METRIC_MEASUREMENT_SYSTEM
                        ? -1
                        : 1;
                }
                return a.size - b.size;
            })
            ),
            map(unitGroup => ({
                group: RecipeConstants.MEASUREMENT_TYPE.WEIGHT.toUpperCase(),
                units: unitGroup
            } as MeasurementUnitGroup))
        );
    }

    private sortedRecipeUnits$(
        source: Observable<MeasurementUnit[]>
    ): Observable<MeasurementUnitGroup> {
        return source.pipe(
            concatMap(units => from(units)),
            filter(
                (unit) => unit.measurementSystem === RecipeConstants.MENU_ITEM_MEASUREMENT_SYSTEM
            ),
            toArray(),
            map(unitGroup => {
                return ({
                    group: 'MENUITEM',
                    units: unitGroup
                } as MeasurementUnitGroup);
            })
        );
    }

   public getSAPUOMunits$(mappedItemsWithoutLiterals : ResolvedItem[], recipeId:string ,customerPK : CustomerPK ,itemRefSource?: ItemReference[] , createNewIngredient?:string ):Observable<ResolvedItem[]> {
    return forkJoin([  
                         this.recipeService.getAllSavedUnsavedIngredients({
                            customerPk : customerPK,
                            ItemReference : (createNewIngredient ? itemRefSource : [] as ItemReference[]),
                            recipeId: recipeId !== "" ? recipeId : RecipeConstants.UNSAVED_RECIPE_ID,
                        }),
            
                        this.unitService.getCurrentEntitlementUOMs$().pipe(first())
            ]).pipe(
                concatMap(([ingredient,sapunits])=>{
                        ingredient?.forEach((ingredient)=>{
                            const unitItem = [] as ResolvedUnitOfMeasure[]
                            ingredient?.configuredCountingUnits?.forEach((CCN)=>{
                                unitItem.push({
                                    key: CCN,
                                    localized: sapunits.single.find((sapUnit) => sapUnit.type === CCN).name,
                                    localizedSymbol: []
                                }) as unknown as ResolvedUnitOfMeasure
                            })
                            mappedItemsWithoutLiterals?.forEach((units)=>{ 
                                if(units?.itemReference?.key === ingredient.itemId){
                                    units?.units?.units.find(((singleUnit)=> singleUnit.group === 'COUNT')).units.push(...unitItem);
                                  if(units?.units?.hasVolumeUnitConfiguration !== undefined)
                                  {
                                    units.units.hasVolumeUnitConfiguration = (ingredient.showVolumeInformation || units.units?.hasVolumeUnitConfiguration)
                                  }
                            }
                        })
                        })
                    return of(mappedItemsWithoutLiterals)
                })
            )
    }

    private batchRecipeYieldUnits$(
        source: Observable<MeasurementUnit[]>
    ): Observable<MeasurementUnitGroup> {
        return source.pipe(
            concatMap(units => from(units)),
            filter(unit => unit.measurementSystem === RecipeConstants.BATCH_RECIPE_MEASUREMENT_SYSTEM),
            toArray(),
            map(unitGroup => ({
                group: 'RECIPE_YIELD',
                units: unitGroup
            } as MeasurementUnitGroup))
        );
    }

    hasMeasurementUnitConfiguration$(
        allUnits: MeasurementUnit[],
        item: ResolvedItem,
        unitType: 'WEIGHT' | 'VOLUME'
    ): Observable<boolean> {
        return from(this.unitConfig[getItemClass(item)][unitType])
            .pipe(
                map(unitConfig$ => unitConfig$(item, allUnits)),
                toArray(),
                concatMap(unitConfigs$ => forkJoin(unitConfigs$)),
                map(x => x.indexOf(true) > -1)
            );
    }
}
function getItemClass(item: ResolvedItem) {
    const g = {
        'RECIPE': 'RECIPE',
        'MENUITEMRECIPE': 'RECIPE',
        'BATCHRECIPE': 'RECIPE',
    };
    return g[item.itemReference.type] ?? item.itemReference.type;

}
